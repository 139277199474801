import { Link, useNavigate } from 'react-router-dom'
import { API_ADR, ProjectStatus, LoadingBar, takePermissionLocalStorage, decodePermission, permissionLogic, AM_UserDateTime} from '../../ApiCenter/API_GET';
import { ColumnsDirective, ColumnDirective, Inject } from '@syncfusion/ej2-react-treegrid';
import { TreeGridComponent, Resize, Filter, InfiniteScroll, VirtualScroll, Page } from '@syncfusion/ej2-react-treegrid';
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import axios from 'axios';
import dayjs from 'dayjs'

import {ProjectsShareForm} from './01_Projects_ShareForm'
import {ProjectsAudioAssociation} from './01_Projects_AudioAssociation'
import {ProjectsAudioTargetSetting1} from './01_Projects_AudioTargetSetting1'
import {ManageTargetAssets} from '../FileTransfer/ManageTargetAssets'
import {ProjectInfoEdit} from './01_Projects_InfoEdit'

import SettingsInputCompositeIcon from '@mui/icons-material/SettingsInputComposite';

import $ from 'jquery';


function ProjectHeaderProjects(props){

    return  <div className="pagetitle">
                <h1>Projects</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active">{props.name}</li>
                    </ol>
                 </nav>
            </div>
}

//var currentData = []
let gridObj;

let expendedStatus = []
export const ProjectsShow = () => {

    const newButtonBar = useRef(null);

    //const [disableButtons, setDisableButtons] = useState(true);
    //const [Selection, setSelection] = useState([]);
    const [valuesTableGrid, setValuesTableGrid] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [operation, setOperation] = useState('Insert')
    const [userPermission, setUserPermission] = useState(takePermissionLocalStorage)
    const flagCodes = useRef({});


    const includesResults = [0, -1]
    const projectsClosed = localStorage.getItem('projectsClosed')
    if(projectsClosed){
        includesResults.push(1)
    }

    const levelPBSRef = useRef(includesResults)

    const targetPanel1Ref = useRef();
    const targetPanel2Ref = useRef();
    const sourcePanelRef = useRef();
    const sharePanelRef = useRef();
    const projectInfoEditRef = useRef();


    useEffect(() => {

        const fetchUrl = async () => {

            const result = await axios.get(API_ADR(`projectList=${levelPBSRef.current.join(',')}`));

            //console.log("RESUL", result.data)
            flagCodes.current = JSON.parse(JSON.parse(result.data.flagsCodes).flagCodes);
            setValuesTableGrid(previous => tempFunction(result.data.dataTable2, result.data.permissions, result.data.userId))
            setUserPermission(decodePermission(result.data.usepem))
            setIsLoading(false)
        };
        fetchUrl();
    }, []);

    const current = $(window).height();
    const projectlistHeight = current - 250;
    document.documentElement.style.setProperty(`--div-height-projectlist`, projectlistHeight + 'px');

    const navigate = useNavigate();


    if(isLoading){
        return <main id="main" className="main"><LoadingBar /></main>
    }

    // custom status column
    function templateStatus(props){
        return <ProjectStatus pstatus={props}/>
    }


    const iconsProjectName = (props) => {

        let letter = ''
        let classIcon = ''
        if(props.lineContent === 'project'){
            letter = 'P'
            classIcon = 'primary'   
        }
        if(props.lineContent === 'batch'){
            letter = 'B'
            classIcon = 'success'
        }
        if(props.lineContent === 'script'){
            letter = 'S'
            classIcon = 'danger'
        }

        let opacityNum = 1;
        if(props.hasOwnProperty('subtasks')){
            if(props.subtasks.length === 0){
                opacityNum = 0.5
            }
        }


        return (
            <>

            <span style={{"marginRight": "5px"}} className={`iconprbchscrp badge bg-${classIcon}`}>{letter}</span>
            {userPermission.editInfoPrBtSpt && props.sharedActive === 1 &&

                <i style={{position: 'relative', float: 'right', fontSize: '11px', paddingTop: '4px', cursor: 'pointer'}} className="bi bi-pencil" onClick={() => infoEditLine(props)}></i>

            }
            <span style={{"opacity": `${opacityNum}`}} title={props.project_name}>{props.project_name}</span>

            </>
        )

    }

    const clientTemplate = (props) => {
        return (
            <div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                <span title={props.client_name}>{props.client_name}</span>
            </div>
        )
    }

    const startDateTemplate = (props) => {
        if(props.startDate !== undefined){
            let currentDate = AM_UserDateTime(props.startDate).split(" ")[0]
            return <span>{currentDate}</span>
        }
    }

    const endDateTemplate = (props) => {
        if(props.endDate !== undefined){
            let currentDate = AM_UserDateTime(props.endDate).split(" ")[0]
            return <span>{currentDate}</span>
        }
    }


    function headerCellInfo(args) { 
        args.node.getElementsByClassName("e-checkbox-wrapper")[0] && args.node.getElementsByClassName("e-checkbox-wrapper")[0].remove(); 
    } 
 
    let checkHowManyProjectsBatches = [];
    let checkHowManyProjects = [];

    function checkboxChange(args){

        var dataSource = gridObj.selectionModule.selectedItems;

        newButtonBar.current.statusBar({
            disabled: true,
            ids: {}
        })
        checkHowManyProjectsBatches = 0;
        checkHowManyProjects = 0;
        let SelectionCheck = []

        dataSource.forEach(function(el, idx){
            if(el.lineContent === 'script'){
                SelectionCheck.push(el.taskID)
            }
            if(el.lineContent === 'batch'){
                checkHowManyProjectsBatches++
            }
            if(el.lineContent === 'project'){
                checkHowManyProjects++
            }
        })

        if(SelectionCheck.length > 0){
            newButtonBar.current.statusBar({
                disabled: false,
                ids: SelectionCheck
            })
        }

    }


    function linkTo_new(props){  
        if(props.target === 'actorscript') {
            navigate('/actorscript');
        } else {
            navigate(`/projectsdetails/${props.ids}/${props.target}`);
        }
        
    }

    /* SHARE RESOURCES */

    function close(message){

        if(message === 'reload'){
            
            const fetchUrl = async () => {
                
                const result = await axios.get(API_ADR(`projectList=${levelPBSRef.current.join(',')}`));

                let reloadData = tempFunction(result.data.dataTable2, result.data.permissions, result.data.userId)
                //setValuesTableGrid(previous => reloadData)
   
                gridObj.setProperties({dataSource:reloadData})

                newButtonBar.current.statusBar({
                    disabled: true,
                    ids: {}
                })
            };
            fetchUrl();
            //setReload(reload + 1)
        } 
    }


    function sharingSystem(props){

        if(props.sharedActive === -1) { return }

        let listLitle = ''
        try{
            listLitle = "Users and Teams:\n" + props.joinGroups.split(',').join('\n')
        }
        catch(err){
            listLitle = props.joinGroups
        }

        let addStyle = {marginRight: '5px', fontSize: '12px', padding: '0px', color: '#c1c1c1'}// gray
        if(listLitle !== null){
            addStyle = {...addStyle, color: '#4154F1'} // blue
        }

        return (
            <>
            {permissionLogic(userPermission, 'assignResources') && props.sharedActive > -1 &&
            <button className="btn btn-sm shadow-none"
                onClick={() => shareResources(props)}
                style={addStyle}
                title={`Share Resources for the ${props.lineContent}`}>
                    <i className="bi bi-share"></i>
            </button>
            }
            <span title={listLitle}>{props.joinGroups}</span>  
            </>
        )
    }

    /* flags template */

    const flagsTemplate = (props) => {

        if(props.language_code !== undefined){

            if(props.lineContent === 'project'){

                let output = []

                props.language_code.forEach(function(el, idx){
                    // split after 2 letters and insert the synmbol -
                    //let splitLanguageCode = el.match(/.{1,2}/g).join('-')

                    let flagCode = flagCodes.current[el]?.flag;
                    let language = flagCodes.current[el]?.language + ' (' + el + ')';
                    if(flagCode && language){
                        output.push(<div key={idx} className={`flag-icon flag-icon-${flagCode}`} title={language} style={{minWidth: '26px', margin: '2px 2px'}}></div>)
                    }

                })
                return <div style={{display: 'flex', flexWrap: 'wrap'}}>{output}</div>
            }

            if(props.lineContent === 'script'){
                // split after 2 letters and insert the synmbol - 
                //let splitLanguageCode = props.language_code.match(/.{1,2}/g).join('-')
                let flagCode = flagCodes.current[props.language_code]?.flag;
                let language = flagCodes.current[props.language_code]?.language + ' (' + props.language_code + ')';

                return flagCode && language && <div 
                    className={`flag-icon flag-icon-${flagCode}`}
                    title={language}
                    style={{width: '26px'}}
                    ></div>
                }
        }
    }

    /* function share resources */
    function shareResources(props){

        const object = {
            infoLevel: props.lineContent,
            idElement: props.idElement,
            nameElement: props.project_name,
            isLocal: props.sharedActive
        }

        sharePanelRef.current.openShareModal(object)

        //setOperation(object)

    }

    /* function audio association */

    function iconAudioAssociation(props){

        if(props.lineContent !== 'project' && props.sharedActive === 1){

            let addStyle = {marginRight: '5px', fontSize: '17px', padding: '0px', color: '#c1c1c1', position: 'relative', top: '2px'}
            if(props.audiobase_set === '1'){
                addStyle = {marginRight: '5px', fontSize: '17px', padding: '0px', color: '#21af00', position: 'relative', top: '2px'}
            }
            if(props.lineContent === 'script'){
                addStyle.fontSize = '12px'
            }

            return (
                props.lineContent === 'batch' ?
                <button className="btn btn-sm shadow-none"
                    onClick={() => openAudioAssocPanel(props)}
                    style={addStyle}
                    title={`Associated Audio Source to Scripts in the Batch "${props.project_name}"`}>
                        <i className="bi bi-soundwave"></i>
                </button> : 
                <i style={addStyle} className="bi bi-soundwave"></i>

            )
        }
    }

    const openAudioAssocPanel = (props) => {
        const numberOfScriptID = props.subtasks.reduce((acc, item) => {

            if (!acc.includes(item.scriptIdGroup)) {
              acc.push(item.scriptIdGroup);
            }
            return acc;
          }, []).length;

          //console.log(props)


          if(numberOfScriptID === 1){

            let languageCodes = props.taskData.subtasks.map((item) => item.language_code);
            let joinedLanguageCodes = languageCodes.join(', ');
            
            const obj = {tyle: 'Source', selection: props.idTarget, info: {script_name: props.taskData.subtasks[0].project_name, languages: joinedLanguageCodes}}

            sourcePanelRef.current.openSourceModal(obj)
          } else {
            targetPanel1Ref.current.receiveTargeID({key: props.idElement, type: 'Source'})
          }
        
        
        //console.log(batchId)
    }

    /***************************************************************************************** */
    function storeStatus(){
        window.localStorage.setItem('currentData', JSON.stringify(expendedStatus));
    }

    function collapsed(args) {
        function arrayRemove(arr, value) {
            return arr.filter(function (geeks) {
                return geeks !== value;
            });
         
        }
        expendedStatus = arrayRemove(expendedStatus, args.data.taskID);
        storeStatus()
    }

    function expanded(args) {
        expendedStatus.push(args.data.taskID)
        storeStatus()
    }

    /***************************************************************************************** */

    const dataBound = () => {
        if (gridObj !== null){
            let cloned = gridObj.addOnPersist;
            gridObj.addOnPersist = function (key) {
                key = key.filter((item) =>  item === "selectedRowIndex" || item === "scrollPosition");
                return cloned.call(this, key);
            }
        }
    }

    /************************************** */

    function closeModalTarget(){

    }

    function openModalTarget(props){

            const numberOfScriptID = props.subtasks.reduce((acc, item) => {

                if (!acc.includes(item.scriptIdGroup)) {
                  acc.push(item.scriptIdGroup);
                }
                return acc;
              }, []).length;



        if(numberOfScriptID === 1){

            let languageCodes = props.taskData.subtasks.map((item) => item.language_code);
            let joinedLanguageCodes = languageCodes.join(', ');
            
            const obj = {type: 'Source', selection: props.idTarget, info: {script_name: props.taskData.subtasks[0].project_name, languages: joinedLanguageCodes}}

            targetPanel2Ref.current.receiveTargeID(obj)
        } else {
            /* send the ID to the child component target panel 1 (01_Projects_AudioTargetSetting1.js */
            targetPanel1Ref.current.receiveTargeID({key: props.idElement, type: 'Target'})
        }
    }

    function iconTargetAssoc(props){
        if(props.lineContent === 'script'){
            let addStyle = {marginRight: '5px', fontSize: '12px', padding: '0px', color: '#F00', cursor: 'default'}
            let title = `No FileType(s) of "${props.project_name}" are set`
            if(props.filetypes_created === '1'){
                addStyle.color =  '#4154F1'
                title = `FileType(s) of "${props.project_name}" are set`
            }

            return(<button className="btn btn-sm shadow-none"
                style={addStyle}
                title={title}>
                    <SettingsInputCompositeIcon style={{fontSize: '10px'}} />

            </button>)
        }
        if(props.lineContent === 'batch' && props.sharedActive === 1){

            let addStyle = {marginRight: '5px', fontSize: '16px', padding: '0px', color: '#4154F1'}

            return (
                <>
                <button className="btn btn-sm shadow-none"
                    onClick={() => openModalTarget(props)}
                    style={addStyle}
                    title={`Set FileTypes to Scripts in Batch "${props.project_name}"`}>
                        <SettingsInputCompositeIcon style={{fontSize: '14px'}} />
                </button>
                </>
            )
        }
    }

    const uploadLinks = (props) => {

        const positionLink = {position: 'relative', padding: '0px', margin: '2px', top: '3px', border: '0px'}
     
        if(props.lineContent === 'script' && props.uploadReference){
            const {batch_id, project_id, script_id} = props.uploadReference;


            let disabled = true;
            let disabledTitle = 'No Filetypes are set'
            if(props.filetypes_created === '1' && props.status !== '1'){
                disabled = false;
                
            }

            const goto = (props) => {
                navigate(props);
            }
            
            return <>

                    <button title={disabled ? disabledTitle : "Open Upload page for the Target"} className="btn btn-sm  btn-outline-primary shadow-none" disabled={disabled} style={positionLink}
                        onClick={() => goto(`/uploads/target-${project_id}-${batch_id}-${script_id}`)}>
                        <i className="bi bi-cloud-upload-fill"></i>
                    </button>
                    <button title={disabled ? disabledTitle : "Open Download page for the Target"} className="btn btn-sm btn-outline-primary shadow-none" disabled={disabled} style={positionLink}
                        onClick={() => goto(`/downloads/target-${project_id}-${batch_id}`)}><i className="bi bi-cloud-download-fill"></i>
                    </button>
            </>
        }
        
        if(props.lineContent === 'project'){
            return <Link title="Open Upload page for the Source" to={`/uploads/source-${props.idElement}`} style={positionLink}><i className="bi bi-cloud-upload-fill" style={{color: 'green'}}></i></Link>
        }  

        if(props.lineContent === 'batch'){
            const {project_id, batch_id} = props.downloadReference;

            return <Link title="Open Download page for the Source" to={`/downloads/source-${project_id}-${batch_id}`} style={positionLink}><i className="bi bi-cloud-download-fill" style={{color: '#0d6efd'}}></i></Link>     
        }
    }


    const closeModalSetting = () => {
        targetPanel2Ref.current.removeUI()
        close('reload')
    }

    const preceedingOpenBatch = (props) => {

        if(props.type === 'Source'){
            sourcePanelRef.current.openSourceModal(props)
        } else { 
            targetPanel2Ref.current.receiveTargeID(props)
        }   
    }


    /* close all the line opened */
    const collapseAll = () => {
        gridObj.collapseAtLevel(1)
        gridObj.collapseAtLevel(0)
        try{
            window.localStorage.setItem('currentData', JSON.stringify([]));
            //close('reload')
        } catch(err){console.log(err)}
    }


    /* search */
    const searchHandler = (props) => {

        if(props === ''){           
            gridObj.search(props)
            setTimeout(() => {
                //gridObj.collapseAll()
            }, 100);
        } else {
            gridObj.search(props)
            //gridObj.expandAll()
        }  
    }

    const columnProjectsBatchesScriptsTemplate = (props) => {
        return <>
                Project / Batch / Script Name
                <button type="button" className="btn btn-outline-primary btn-sm" 
                    onClick={collapseAll}
                    style={{height: '19px', padding: '0px 5px', lineHeight: '1', marginTop: '5px', float: 'right', fontSize: '11px'}}
                    title="Collapse all the rows"
                >Collapse</button>
                </>          
    }

    const columnSourceAudioTemplate = (props) => {
        return <span title="Source Audio">Source Audio</span>
    }

    const columnTargetAudioTemplate = (props) => {
        return <span title="Target Settings">Target Settings</span>
    }



    /* info Edit Line */

    const infoEditLine = (props) => {
        projectInfoEditRef.current.openInfoModal(props)
    }

    function changeView(props){

        levelPBSRef.current = props

        const fetchUrl = async () => {
            const result = await axios.get(API_ADR(`projectList=${levelPBSRef.current.join(',')}`));
            gridObj.setProperties({dataSource:tempFunction(result.data.dataTable2, result.data.permissions, result.data.userId)})
            //setValuesTableGrid(previous => )
            setTimeout(function(){
                setIsLoading(false)
            }, 300)
            
        };
        fetchUrl();
    }

    const pageSettings = { pageSize: 50, pageSizeMode: 'Root' };
    const infiniteOptions = { initialBlocks: 3 };

    return (
        <>
        <main id="main" className="main">
            
            <ProjectsShareForm ref={sharePanelRef} typeofOp={operation} close={close}/>
            <ProjectsAudioAssociation ref={sourcePanelRef} close={close}/>
            <ProjectsAudioTargetSetting1 ref={targetPanel1Ref} closeModalTarget={closeModalTarget} preceedingOpenBatch={preceedingOpenBatch} />
            <ManageTargetAssets ref={targetPanel2Ref} onApplySettings={closeModalSetting} />
            <ProjectInfoEdit ref={projectInfoEditRef} close={close} />
            <ProjectHeaderProjects name="Projects" />{/* header*/}
            <div className="card">
                <div className="card-body mt-3 projectsmodule">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <ClonedButtons ref={newButtonBar} onClickNew={linkTo_new} />
                        <div className="d-flex align-items-center">
                            <FilterStatus data={levelPBSRef.current} updateConfig={changeView} />
                            <InputSearch onSearch={searchHandler} />
                        </div>
                    </div>
                    <div className="projectlist">
                        <TreeGridComponent 
                            id="listProjectTable"
                            ref={grid => gridObj = grid} 
                            dataSource={valuesTableGrid}
                            treeColumnIndex={1}
                            childMapping='subtasks'
                            height="100%"
                            dataBound={dataBound}
                            autoCheckHierarchy={true}
                            //enableCollapseAll={true}
                            headerCellInfo={headerCellInfo}
                            checkboxChange={checkboxChange}
                            collapsed={collapsed}
                            expanded={expanded}
                            enablePersistence={true}
                            expandStateMapping='IsExpanded'
                            selectionSettings={{enableToggle: false}}
                            allowSelection={true}
                            allowResizing={true}
                            gridLines='Both'
                            searchSettings={{ hierarchyMode: 'Both', fields: ['project_name', 'language_code', 'client_name', 'pm_name', 'share', 'joinGroups', 'searchDate']}}
                            /*enableInfiniteScrolling={true}*/
                            enableVirtualization={false}
                            //infiniteScrollSettings={infiniteOptions}
                            pageSettings={pageSettings}
                            allowPaging={true}
                            //onRowExpanding={handleRowExpanding}                             
                            >
                            <ColumnsDirective>
                                <ColumnDirective field='taskID' headerText='#' width='0' textAlign='Center'/>
                                <ColumnDirective field='project_name' headerTemplate={columnProjectsBatchesScriptsTemplate} headerText='Project / Batch / script Name' template={iconsProjectName} width='280' minWidth='280' showCheckbox={true} />
                                <ColumnDirective field='language_code' template={flagsTemplate} headerText='Languages' width='210' customAttributes={ { class: 'removeWrap' }}/>
                                <ColumnDirective field='client_name' template={clientTemplate} headerText='Client Name' width='120'/>
                                <ColumnDirective field='pm_name' headerText='PM Name' width='100' />
                                <ColumnDirective field='share' headerText='Share' width='120' template={sharingSystem} textAlign='Left'  />
                                {permissionLogic(userPermission, 'SourceUploadFiles') &&
                                <ColumnDirective field='' headerText='Source Audio' width='50' headerTemplate={columnSourceAudioTemplate} template={iconAudioAssociation} textAlign='Center' customAttributes={{ class: 'removemargins' }}/> }
                                {/*permissionLogic(userPermission, 'setAdaptationMarks') &&
                                <ColumnDirective field='' headerText='A.Marks' width='50' template={iconAdaptMarksAssociation} textAlign='Center' maxWidth={50} minWidth={50} customAttributes={ { class: 'removemargins' }}/>*/}
                                {permissionLogic(userPermission, 'TargetUploadFiles') &&
                                <ColumnDirective field='' headerText='Target Settings' headerTemplate={columnTargetAudioTemplate} width='50' template={iconTargetAssoc} textAlign='Center' customAttributes={{ class: 'removemargins' }}/>}
                                <ColumnDirective field='' headerText='' /*headerTemplate={columnTargetAudioTemplate}*/ width='50' template={uploadLinks} textAlign='Center' customAttributes={{ class: 'removemargins' }}/>
                                <ColumnDirective field='startDate' headerText='Start' template={startDateTemplate} width='130' textAlign='Center' /*format='d/M/y' type='date'*//>
                                <ColumnDirective field='endDate' headerText='End' template={endDateTemplate} width='130' textAlign='Center' /*format='d/M/y' type='date'*//>
                                <ColumnDirective field='status' headerText='Status' template={templateStatus} width='100' />
                                <ColumnDirective field='joinGroups' headerText='joinGroups' width='100' visible={false} />
                                <ColumnDirective field='searchDate' headerText='joinGroups' width='100' visible={false} />

                                
                            </ColumnsDirective>
                            <Inject services={[Resize, Filter, VirtualScroll, Page, /*InfiniteScroll*/]}/>
                        </TreeGridComponent>
                    </div>
                </div>
            </div>
        </main>
        </>
    )
}



function tempFunction(table, permissionsTable, userId){

    function openNodeYorN(id){
        if(expendedStatus.includes(id)){
            return true
        } else {
            return false
        }
    }

    if(localStorage.getItem('currentData') !== null){
        expendedStatus = JSON.parse(localStorage.getItem('currentData'))
    }

    let outputArray = [];
    let previousProjectName = 0;
    let previousBatcvhName = 0;
    let counterProject = -1;
    let counterBatch = -1;
    let projectShared = -1;
    let batchShared = -1;
    const superuser = permissionsTable[0].admin === '1';
    const internalResource = permissionsTable[0].internal_resource === '1';

    table.forEach(function(el, idx){

        if(el.project_id !== previousProjectName){

            projectShared = superuser || el.pm_id === userId || permissionsTable.some((i) => { return i['project_id'] === el.project_id;}) ? 1 : -1;
            counterBatch = -1;
            counterProject++;
            outputArray.push({
                taskID: `P${el.project_id}`,
                idElement: el.project_id,
                project_name: el.project_name,
                client_name: el.client_name,
                status: el.project_status,
                lineContent: 'project',
                joinGroups: el.project_groups,
                IsExpanded: openNodeYorN(`P${el.project_id}`),
                subtasks: [],
                sharedActive: projectShared
            })
        }
        if((el.batch_id !== previousBatcvhName) && (el.batch_name !== null)){

            batchShared = superuser || projectShared >-1 || el.pm_id === userId || permissionsTable.some((i) => { return i['batch_id'] === el.batch_id;}) ? 1 : -1;

            counterBatch++;
            outputArray[counterProject].subtasks.push({
                taskID: `B${el.batch_id}`,
                idElement: el.batch_id,
                downloadReference: {
                    project_id: el.project_id,
                    batch_id: el.batch_id
                },
                idTarget: el.script_id,
                project_name: el.batch_name,
                startDate: new Date(el.batch_start_date),
                endDate: new Date(el.batch_end_date),
                searchDate: `${dayjs(el.batch_start_date).format('D-MMM-YYYY')}|${dayjs(el.batch_end_date).format('D-MMM-YYYY')}`,
                pm_name: el.pm_name,
                status: el.batch_status,
                lineContent: 'batch',
                joinGroups: el.batch_groups,
                audiobase_set: el.audiobase_set,
                IsExpanded: openNodeYorN(`B${el.batch_id}`),
                subtasks: [],
                sharedActive: batchShared
            })
        }
        if(el.script_name !== null){

            outputArray[counterProject].subtasks[counterBatch].subtasks.push({
                taskID: parseInt(el.script_lang_id),
                idElement: el.script_lang_id,
                uploadReference: {
                    project_id: el.project_id,
                    batch_id: el.batch_id,
                    script_id: el.script_id
                },
                //refBatch: el.batch_id,
                project_name: el.script_name,
                language_code: el.language_code,
                startDate: new Date(el.script_start_date),
                endDate: new Date(el.script_end_date),
                searchDate: `${dayjs(el.script_start_date).format('D-MMM-YYYY')}|${dayjs(el.script_end_date).format('D-MMM-YYYY')}`,
                pm_name: el.pm_name,
                status: el.script_status,
                joinGroups: el.script_lang_groups,
                adaptmarks_set: el.adaptmarks_set,
                scriptIdGroup: el.script_id,
                filetypes_created: el.filetypes_created,
                audiobase_set: el.audiobase_set,
                IsExpanded: openNodeYorN(parseInt(el.script_lang_id)),
                lineContent: 'script',
                sharedActive: (superuser || projectShared>-1 || batchShared>-1 || el.pm_id === userId) ? internalResource ? 1 : 0 : 
                    permissionsTable.some((i) => { return i['script_language_id'] === el.script_lang_id }) ? 0 : -1
            })
        }

        previousProjectName = el.project_id;
        previousBatcvhName = el.batch_id;
        
    })

    outputArray.forEach(function(el){

        let projectsLanguages = []
        el.subtasks.forEach(function(el2){
            el2.subtasks.forEach(function(el3){
                projectsLanguages.push(el3.language_code)
            })
        })

        /* remove duplicates and sort */
        projectsLanguages = [...new Set(projectsLanguages.sort())]
        el.language_code = projectsLanguages
    }) 
    return outputArray
}

const ClonedButtons = forwardRef((props, ref) => {

    const [disableButtons, setDisableButtons] = useState({
        disabled: true,
        ids: {}
    });
    // eslint-disable-next-line  
    const [userPermission, setUserPermission] = useState(takePermissionLocalStorage)

    useImperativeHandle(ref, () => ({
        statusBar(props) {
            setDisableButtons(previous => props)
        },
    }));

    const linkTo = (target) => {
        props.onClickNew({
            target: target,
            ids: disableButtons.ids.join('-')
        })
    }

    return (
        <div id="ProjectsMatrix" className="btn-group mr-2 btn-group-sm flex-wrap" role="group" aria-label="First group" /*style={{maxWidth: '800px'}}*/>
        {
        // OVERVIEW BUTTON
        permissionLogic(userPermission, 'view_Overview') &&
            <button className="btn btn-sm" onClick={() => linkTo('overview')} disabled={disableButtons.disabled}><i className="bi bi-graph-up" title="Open the Overview"></i> Overview</button>
        }
        {permissionLogic(userPermission, 'view_Breakdown') &&
            <button className="btn btn-sm" onClick={() => linkTo('breakdown')} disabled={disableButtons.disabled}><i className="ri-list-unordered" title="Open BreakDown"></i> BreakDown</button>
        }
        {permissionLogic(userPermission, 'view_ScriptBase') &&
        <button title="Open Script" className="btn btn-sm" onClick={() => linkTo('script')} disabled={disableButtons.disabled}><i className="bx bx-grid" title="Open Script"></i> Script Grid</button>
        }
        {permissionLogic(userPermission, 'view_ExcelModule') &&
        <button className="btn btn-sm" onClick={() => linkTo('excel')} disabled={disableButtons.disabled}><i className="bx bx-spreadsheet" title="Open the Excel"></i> Script Excel</button>
        }
        {permissionLogic(userPermission, 'view_GantChart') &&
        <button className="btn btn-sm" onClick={() => linkTo('gantt')} disabled={disableButtons.disabled}><i className="ri-map-pin-time-line" title="Open the Gantt Chart"></i> Gantt</button>
        }
        {permissionLogic(userPermission, 'view_Trello') &&
        <button className="btn btn-sm" onClick={() => linkTo('production')} disabled={disableButtons.disabled}><i className="ri-task-line" title="Open the Production"></i> Production</button>
        }
        {permissionLogic(userPermission, 'view_Media') &&
        <button className="btn btn-sm" onClick={() => linkTo('media')} disabled={disableButtons.disabled}><i className="bi bi-soundwave" title="Open Media"></i> Media</button>
        }
    </div>
    )


})

const InputSearch = (props) => {

    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        let timeout;
        const handleSearch = () => {
            // Qui puoi inserire la tua logica di ricerca
            props.onSearch(inputValue)
        };

        timeout = setTimeout(() => {

            if(inputValue === '') {
                localStorage.removeItem('projectsSearch')
            } else {
                localStorage.setItem('projectsSearch', inputValue)
            }
            
            handleSearch();
        }, 500);

        return () => {
            clearTimeout(timeout);
        }
    // eslint-disable-next-line
    }, [inputValue]);

    useEffect(() => {

        const isSearching = localStorage.getItem('projectsSearch')
        if(isSearching !== null && isSearching !== ''){
            setInputValue(isSearching)
        }

    }, []);

    const searchValue = (e) => {
        setInputValue(e.target.value)
    }

    return <input type="search" className="form-control form-control-sm" placeholder="Search" 
                style={inputValue === '' ? {position: 'relative', marginTop: '-2px', width: '200px', height: '30px'}
                                        : {position: 'relative', marginTop: '-2px', width: '200px', height: '30px', background: '#ff00003b'}}
                onChange={searchValue} value={inputValue} spellCheck={false} />
}

const FilterStatus = (props) => {
    const switchClosed = useRef(null);

    // Use internal state to keep track of the checkbox value
    const [isChecked, setIsChecked] = useState(props.data.includes(1));

    const changeView = () => {
        const chosenView = [0, -1];

        setIsChecked(switchClosed.current.checked);

        if(switchClosed.current.checked){
            chosenView.push(1);
            localStorage.setItem('projectsClosed', 'true')
        } else {
            localStorage.removeItem('projectsClosed')
        }

        setTimeout(function() {
            props.updateConfig(chosenView);
        }, 200);
    };

    const style = {height: '22px', width: '44px', marginRight: '4px', marginTop: '1px', cursor: 'pointer', }

    return (
        <><label className="form-check-label" htmlFor="flexSwitchCheckChecked" style={{fontSize: '14px', cursor: 'pointer',width: '85px'}}>Show Closed</label>
                <div className="form-check form-switch" title="Include closed Projects/Batches/scripts" style={{paddingRight: '5px', cursor: 'pointer'}}>       
            <input 
                ref={switchClosed}
                style={style}
                className="form-check-input" 
                type="checkbox" 
                id="flexSwitchCheckChecked" 
                onChange={changeView} 
                checked={isChecked}                
            />
    </div></>
    );  
}