import React, { useEffect, useState, useImperativeHandle, useRef, forwardRef } from "react"
import axios from 'axios';
import { API_ADR, SpinnerCentered } from '../../ApiCenter/API_GET';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Selection} from '@syncfusion/ej2-react-grids';
import SettingsInputCompositeIcon from '@mui/icons-material/SettingsInputComposite';

var seletedLine;
let selectionGrid 


export const ProjectsAudioTargetSetting1 = forwardRef((props, ref) => {
   
    const [modalSettings, setModalSettings] = useState({
        showModal: false,
        isLoading: false,
        selectedID: null,
        disabledButton: true,
        type: null
    })

    const [dataTable, setDatatable] = useState([])

    useImperativeHandle(ref, () => ({
        receiveTargeID(props) {

            setModalSettings(previous => ({
                showModal: true,
                isLoading: true,
                selectedID: props.key,
                type: props.type,
                disabledButton: true
            }))

        }
    }));

    useEffect(() => {

        async function loadData(){

            await axios.get(API_ADR(`targetAudioAssociation=${modalSettings.selectedID}`)).then(function (response) {

                setDatatable(response.data.infoScripts)
                setModalSettings(previous => ({
                    ...previous,
                    showModal: true,
                    isLoading: false,
                }))
            })
        }

        if(modalSettings.selectedID !== null){
            loadData()
        }

        window.removeEventListener('dblclick', proceedDoubleclick);
        window.addEventListener('dblclick', proceedDoubleclick);

        return () => {
            window.removeEventListener('dblclick', proceedDoubleclick);
        };

        
    // eslint-disable-next-line 
    }, [modalSettings.selectedID])

    const iconTemplateTargetAssoc = (props) => {


            let addStyle = {marginRight: '5px', fontSize: '16px', padding: '0px', color: '#c1c1c1', cursor: 'default'}
            if(props.filetypes_created === '1'){
                addStyle.color =  '#4154F1'
            } else {
                addStyle.color =  '#F00'
            }

            return (
                <>
                <button className="btn btn-sm shadow-none"

                    style={addStyle}
                    title={`Set FileTypes for the scripts in "${props.project_name}" batch`}>
                        {/*<i className="bi bi-soundwave"></i>*/}
                        <SettingsInputCompositeIcon style={{fontSize: '14px'}} />
                </button>
                </>
            )
        
    }
    

    const closeModal = () => {
        setModalSettings(previous => ({
            isLoading: false,
            showModal: false,
            selectedID: null,
            disabledButton: true
        }))
    }

    const rowSelected = (args) => {
        seletedLine = args.data;
        setModalSettings(previous => ({
            ...previous,
            disabledButton: false
        }))
    }

    const proceedDoubleclick = (e) => {
        if(e.target.closest('.allowContinue') !== null){
            goTothebatch()
        }
    }

    const goTothebatch = () => {
        const obj = {
            type: modalSettings.type, selection: seletedLine.script_id, info: seletedLine
        }
        props.preceedingOpenBatch(obj)
        closeModal()
    }

    if(modalSettings.showModal === false){return null}
    if(modalSettings.isLoading === true){return <SpinnerCentered />}
    return (
            <div className="modal fade show" id="AudioMateMenu" tabIndex="-1" aria-modal="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" >
                    <div className="modal-content" id="AudioMateWindowMenu" >
                        <div className="modal-header" style={{padding: '7px 10px 5px 16px'}}>
                            <h5 className="modal-title">
                                {modalSettings.type === 'Source' ? <i className="bi bi-soundwave"></i> : <SettingsInputCompositeIcon style={{fontSize: '16px'}} />} Audio {modalSettings.type} Setting</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                        </div>
                        <div className="modal-body">
                            {modalSettings.type === 'Source' ? 'Select the Script you want to setup (or double click on the line) to open the editor page.' :
                                'Select the Script you want to setup (or double click on the line) to open the editor page.'}
                            <div style={{marginTop: '10px', height: '500px'}}>
                                <GridComponent
                                    ref={grid => selectionGrid = grid} 
                                    dataSource={dataTable}
                                    width={'765'}
                                    height={'100%'}
                                    allowTextWrap={true}
                                    rowSelected={rowSelected}
                                    selectionSettings={{enableToggle: false}}
                                    >
                                    <ColumnsDirective>
                                            {modalSettings.type === 'Target' &&
                                            <ColumnDirective template={iconTemplateTargetAssoc} field='filetypes_created' headerText='' width='30' customAttributes={{ class: 'allowContinue'}} />}
                                            <ColumnDirective field='script_name' headerText='Script in Batch' width='190' customAttributes={{ class: 'allowContinue'}} />
                                            <ColumnDirective field='languages' headerText='Files' width='190' customAttributes={{ class: 'allowContinue'}} />
                                    </ColumnsDirective>
                                    <Inject services={[Selection]} />
                                </GridComponent>
                                </div>
                                <div className="text-center">
                                    <button className="btn btn-sm btn-primary mt-3" style={{marginTop: '15px'}} disabled={modalSettings.disabledButton} onClick={goTothebatch}>Continue &gt;&gt;&gt;</button>
                                </div>
                            
                        </div>
                    </div>
                </div>
            </div>
    )


})



